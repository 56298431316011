import React from 'react';

const Info = () => {
    return (
        <div style={{ textAlign: 'left', width: '80%' }}>
            <h3>What I do</h3>
            <p>I am a software engineer working with the cross-platform front-end frameworks. The works range from mobile, tablet to web applications using React, React Native and Flutter.</p>
            <h3>What I am passionate about</h3>
            <p>I would love to do creative designs and develop on web and mobile platforms.</p>
            <h3>My Goal?</h3>
            <p>Nobody can predict the future, but I have a goal of becoming one of the best Software Engineers in the near future!</p>
        </div>
    );
};

export default Info;