import React from 'react';

const PageBreak = () => (
    <div
        style={{
            width: '30%',
            margin: 'auto',
            borderColor: '#c6e2ff',
            opacity: '50%',
            borderBottomWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderTopWidth: 5,
            borderStyle: 'solid',
            marginTop: 30,
            marginBottom: 30,
        }}
    />
);

export default PageBreak;
