// Template

import React from 'react';

// M-UI Components

// Constants

// Componenets
import BasicDonutChart from '../../components/BasicDonutChart';

const ProjectContribution = (props) => {
    const { series, options } = props;
    return (
        <div
            style={{
                paddingTop: 10,
                paddingBottom: 10,
            }}
        >
            <BasicDonutChart
                series={series}
                options={options}
            />
        </div>
    )
};

export default ProjectContribution;
