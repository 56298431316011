import React, { useState } from 'react';
// M-UI components

// Animations
import {useTrail, a} from 'react-spring'

// Components
import TechIcons from './TechIcons';

function Trail({ open, children, ...props }) {
    const items = React.Children.toArray(children)
    const trail = useTrail(items.length, {
        config: { mass: 5, tension: 2000, friction: 200 },
        opacity: open ? 1 : 0,
        x: open ? 0 : 20,
        height: open ? 30 : 0,
        from: { opacity: 0, x: 20, height: 0 },
    })
    return (
      <div className="trails-main" {...props}>
        <div>
          {trail.map(({ x, height, ...rest }, index) => (
            <a.div
              key={items[index]}
              className="trails-text"
              style={{ ...rest, transform: x.interpolate((x) => `translate3d(0,${x}px,0)`) }}>
              <a.div style={{ height }}>{items[index]}</a.div>
            </a.div>
          ))}
        </div>
      </div>
    )
  }

const WelcomeInfo = (props) => {
    const { isSmallHieght, screenHeight } = props;
    const [open] = useState(true);
    return (
        <div
            style={{
                width: '100%',
                height: isSmallHieght(screenHeight) ? screenHeight : screenHeight * 0.75,
                backgroundColor: '#2867BC',
                paddingTop: isSmallHieght(screenHeight) ? 25 : 65,
                borderWidth: 0,
                borderBottomWidth: 10,
                borderColor: '#f8f1f1',
                borderStyle: 'dashed',
            }}
        >
            <Trail open={open} >
                <h1 className='name-header'>{'Aung San Oo'.toUpperCase()}</h1>
                <h2 className='sub-header'>Front End Engineer</h2>
                <TechIcons />
                <p className={isSmallHieght(screenHeight) ? 'intro-paragraph ' : 'intro-paragraph small'}>
                    I am a Front End Engineer who design, develop, debug, deploy and maintain software solutions with the experience of over two years in the field.
                    Work on platforms such as Mobile Applications and Web Applications
                    using <span className='intro-bold'>React</span>, <span className='intro-bold'>React Native</span> and <span className='intro-bold'>Flutter</span> with languages such as <span className='intro-bold'>JavaScript</span> and <span className='intro-bold'>Dart</span>
                    . I also have hands-on experiences on the backend technologies 
                    such as <span className='intro-bold'>NodeJS</span>, <span className='intro-bold'>ExpressJS</span>, and <span className='intro-bold'>MongoDB</span>.
                </p>
            </Trail>
        </div>
    );
}

export default WelcomeInfo;
