// Template

import React from 'react';

// M-UI Components

// Constants

const ProjectInfo = (props) => {
    const { info } = props.project;
    return (
        <div
            style={{
                paddingTop: 10,
                paddingBottom: 10,
                minHeight: 85,
            }}
        >
            <p
                style={{
                    textAlign: 'left',
                    paddingLeft: 10, paddingRight: 10,
                }}
            >
                {info}
            </p>
        </div>
    )
};

export default ProjectInfo;
