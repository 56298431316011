import React, { Component } from 'react';
import {experiences, experienceChartOptions} from '../../util/experiences/Data';
import MultiSeriesRangeChart from '../../components/MultiSeriesRangeChart';
import WorkExperiences from './WorkExperiences';
import TrackVisibility from 'react-on-screen';
import Bounce from 'react-reveal/Bounce';

class Experiences extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: experiences,
            options: experienceChartOptions,
        }
    }

    render() {
        const { series, options } = this.state;
        return (
            <div className='experience-container' id='Experiences'>
                <Bounce right>
                    <h1 className='header'>Experiences</h1>
                    <WorkExperiences />
                    <h3 style={{ width: '70%', margin: 'auto', marginTop: 20, marginBottom: 20, }}>
                        My experiences can be broken down with the following timeline chart
                    </h3>
                </Bounce>
                <TrackVisibility partialVisibility once>
                {({ isVisible }) => isVisible ? (
                    <Bounce right>
                        <MultiSeriesRangeChart
                            series={series}
                            options={options}
                        />
                    </Bounce>
                ) : <div style={{ height: 500 }} />}
                </TrackVisibility>
            </div>
        )
    }
};

export default Experiences;
