import React from 'react';
import { Link } from 'react-scroll';

import { Button } from '@material-ui/core';

const NavBarButtons = (props) => {
    const { menu } = props;
    return (
        <Button color="secondary" >
            <Link  to={menu} spy={true} smooth={true} offset={-95}>{menu}</Link>
        </Button>
    )
}

export default NavBarButtons;
