// Template

import React from 'react';

// M-UI Components
import { Grid } from '@material-ui/core';

// Constants
import { theme } from '../../util/constants/theme';

// Components
import ProjectPlatfroms from './ProjectPlatfroms';

const ProjectHeader = (props) => {
    const { name, platform } = props.project;
    return (
        <div
            style={{
                paddingTop: 10,
                paddingBottom: 10,
                backgroundColor: theme.main,
                paddingLeft: 10,
                paddingRight: 10,
            }}
        >
            <Grid container spacing={0} alignItems='center'>
                <Grid item lg={4} md={4} sm={12} xs={12} container justify='flex-start'>
                    
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <h3 style={{ color: theme.white }}>{name}</h3>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12} container justify='flex-end'>
                    <ProjectPlatfroms
                        platform={platform}
                    />
                </Grid>
            </Grid>
        </div>
    )
};

export default ProjectHeader;
