import moment from 'moment';

export const experiences = [
    {
        name: 'SUTD',
        data: [
            {
                x: 'React',
                y: [
                    new Date('2018-10-01').getTime(),
                    new Date('2019-09-01').getTime()
                ]
            },
            {
                x: 'React Native',
                y: []
            },
            {
                x: 'Flutter',
                y: []
            },
            {
                x: 'JavaScript',
                y: [
                    new Date('2018-10-01').getTime(),
                    new Date('2019-09-01').getTime()
                ]
            },
            {
                x: 'Dart',
                y: []
            },
            {
                x: 'MongoDB',
                y: [
                    new Date('2018-10-01').getTime(),
                    new Date('2019-09-01').getTime()
                ]
            },
            {
                x: 'NodeJS',
                y: [
                    new Date('2018-10-01').getTime(),
                    new Date('2019-09-01').getTime()
                ]
            },
            {
                x: 'ExpressJS',
                y: [
                    new Date('2018-10-01').getTime(),
                    new Date('2019-09-01').getTime()
                ]
            },
        ]
    },
    {
        name: 'MGEC',
        data: [
            {
                x: 'React',
                y: [
                    new Date('2019-09-01').getTime(),
                    new Date('2019-12-01').getTime()
                ]
            },
            {
                x: 'Flutter',
                y: [
                    new Date('2019-10-01').getTime(),
                    new Date('2020-01-01').getTime()
                ]
            },
            {
                x: 'React Native',
                y: [
                    new Date('2019-09-01').getTime(),
                    new Date('2020-02-01').getTime()
                ]
            },
            {
                x: 'JavaScript',
                y: [
                    new Date('2019-09-01').getTime(),
                    new Date('2020-02-01').getTime()
                ]
            },
            {
                x: 'Dart',
                y: [
                    new Date('2019-10-01').getTime(),
                    new Date('2020-01-01').getTime(),
                ]
            },
            {
                x: 'MongoDB',
                y: [
                    new Date('2019-09-01').getTime(),
                    new Date('2019-12-01').getTime()
                ]
            },
            {
                x: 'NodeJS',
                y: [
                    new Date('2019-09-01').getTime(),
                    new Date('2019-12-01').getTime()
                ]
            },
            {
                x: 'ExpressJS',
                y: [
                    new Date('2019-09-01').getTime(),
                    new Date('2019-12-01').getTime()
                ]
            },
        ]
    },
    {
        name: 'SUTD-LB',
        data: [
            {
                x: 'React',
                y: [
                    new Date('2021-02-01').getTime(),
                    new Date('2021-03-01').getTime()
                ]
            },
            {
                x: 'React Native',
                y: [
                    new Date('2020-01-01').getTime(),
                    new Date().getTime()
                ]
            },
            {
                x: 'Flutter',
                y: [
                    new Date('2021-04-01').getTime(),
                    new Date().getTime()
                ]
            },
            {
                x: 'JavaScript',
                y: [
                    new Date('2020-01-01').getTime(),
                    new Date().getTime()
                ]
            },
            {
                x: 'Dart',
                y: [
                    new Date('2021-04-01').getTime(),
                    new Date().getTime()
                ]
            },
        ]
    },
];

export const experienceChartOptions = {
    chart: {
        height: 550,
        type: 'rangeBar'
    },
    plotOptions: {
        bar: {
            horizontal: true,
            barHeight: '90%',
        }
    },
    xaxis: {
        type: 'datetime',
    },
    stroke: {
        width: 1
    },
    fill: {
        type: 'solid',
        opacity: 0.6
    },
    legend: {
        position: 'top',
        horizontalAlign: 'left'
    },
    dataLabels: {
        enabled: true,
        formatter: function(val) {
          var a = moment(val[0])
          var b = moment(val[1])
          var diff = b.diff(a, 'months')
          return diff + (diff > 1 ? ' months' : ' month')
        }
      },
};
