import React from 'react';
import Bounce from 'react-reveal/Bounce';

// M-UI Components
import { Grid } from '@material-ui/core';

// Componenets
import ProjectCard from './ProjectCard';

// Data
import {
    projects,
    SDLC_LB_LC,
    SDLC_LB_LC_Options,
    SDLC_MGEC_SP,
    SDLC_MGEC_SP_Options,
    SDLC_MGEC_D,
    SDLC_MGEC_D_Options,
    SDLC_MGEC_MMALPR,
    SDLC_MGEC_MMALPR_Options,
} from '../../util/projects/Data';

const Projects = () => {
    return (
        <div className='project-container' id='Projects'>
            <Bounce right>
                <h1 className='header'>Projects</h1>
                <p style={{ marginRight: 30, marginLeft: 30 }}>
                    During the past three years, my recent projects include mobile applications on Android and iOS, tablet applications, react web applications,  
                    backend servers developments and working with database. The fields I contributed are highlighted with the charts.
                </p>
            </Bounce>
            <Grid container spacing={2}>
                <Grid item container lg={6} md={6} sm={12} xs={12} justify='center'>
                    <ProjectCard
                        project={projects[0]}
                        series={SDLC_LB_LC}
                        options={SDLC_LB_LC_Options}
                    />
                </Grid>
                <Grid item container lg={6} md={6} sm={12} xs={12} justify='center'>
                    <ProjectCard
                        project={projects[1]}
                        series={SDLC_MGEC_SP}
                        options={SDLC_MGEC_SP_Options}
                    />
                </Grid>
                <Grid item container lg={6} md={6} sm={12} xs={12} justify='center'>
                    <ProjectCard
                        project={projects[2]}
                        series={SDLC_MGEC_D}
                        options={SDLC_MGEC_D_Options}
                    />
                </Grid>
                <Grid item container lg={6} md={6} sm={12} xs={12} justify='center'>
                    <ProjectCard
                        project={projects[3]}
                        series={SDLC_MGEC_MMALPR}
                        options={SDLC_MGEC_MMALPR_Options}
                    />
                </Grid>
            </Grid>
        </div>
    )
};

export default Projects;
