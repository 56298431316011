import React, { useState } from 'react';
import Bounce from 'react-reveal/Bounce';


// M-UI Components
import { Grid } from '@material-ui/core';

// Components - Charts
import BasicBarChart from '../components/BasicBarChart';
import BasicDonutChart from '../components/BasicDonutChart';

// Data
import {
    skillsData,
    skillChartOptionsData,
    fullStackData,
    fullStackOptionsData,
    SDLCData,
    SDLCOptionsData,
} from '../util/skills/Data';

const Skills = () => {
    const [ skills ] = useState(skillsData);
    const [ skillOptions ] = useState(skillChartOptionsData);
    const [ fullStack ] = useState(fullStackData);
    const [ fullStackOptions ] = useState(fullStackOptionsData);
    
    return (
        <div className='skill-container' id='Skills'>
            <Bounce right>
                <h1 className='header'>Skills</h1>
                <p style={{ marginRight: 30, marginLeft: 30 }}>
                    The skill charts are generally based on the total number of months spent on each of the technologies. 
                    Even though I have spent more time on the front-end technologies than back-end, 
                    there are hands-on experiences on backend as well.
                </p>
                <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <BasicBarChart
                            series={skills}
                            options={skillOptions}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <BasicDonutChart
                            series={fullStack}
                            options={fullStackOptions}
                        />
                        Full Stack Contribution
                        <BasicDonutChart
                            series={SDLCData}
                            options={SDLCOptionsData}
                        />
                        SDLC Contribution
                    </Grid>
                </Grid>
            </Bounce>
        </div>
    )
};

export default Skills;
