import React from 'react';
import Rotate from 'react-reveal/Rotate';
// Sections
import MyPhoto from './MyPhoto';
import Info from './Info';
// M-UI Components
import { Grid } from '@material-ui/core';
import './About.css';

const About = () => {
    return (
        <div className='project-container' id='About'>
            <Rotate top left cascade>
                <h1 className='header'>About</h1>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} container justify='center' alignItems='center'>
                        <MyPhoto />
                    </Grid>
                    <Grid item xs={12} sm={6} container justify='center'>
                        <Info />
                    </Grid>
                </Grid>
            </Rotate>
        </div>
    )
};

export default About;
