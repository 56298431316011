import React, { useEffect } from 'react';
import MainPage from './screens/MainPage';

import ReactGA from "react-ga";  //Google Analytics

// M-UI styling
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles'
import {indigo, pink} from '@material-ui/core/colors'


// Custom M-UI theme
const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#757de8',
            main: '#2867BC',
            dark: '#002984',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff79b0',
            main: '#FFFFFF',
            dark: '#c60055',
            contrastText: '#000',
        },
    openTitle: indigo['400'],
    protectedTitle: pink['400'],
    type: 'light'
    }
});

const App = () => {
    useEffect(() => {
        ReactGA.initialize('UA-186572952-1');
        ReactGA.pageview(window.location.pathname);
    });
    
    return (
        <MuiThemeProvider theme={theme}>
            <MainPage />
        </MuiThemeProvider>
    )
};

export default App;