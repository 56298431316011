export const Works = [
    {
        name: 'SUTD',
        fullName: 'Singapore University of Technology and Design',
        detail: '',
        role: 'Web Application Developer',
        roleDetial: 'Dashboard for cybersecurity penetration testing',
        duration: 'Oct 2018 - Sep 2019',
        index: 1,
        mobile: [],
        web: ['react'],
        backend: ['nodeJS', 'express', 'mongoDB'],
    },
    {
        name: 'MGEC',
        fullName: 'Myanmar Gateway Engineering & Consultancy Pte Ltd',
        detail: '',
        role: 'Front End Developer',
        roleDetial: 'Freelance mobile and web applications developer',
        duration: 'Sep 2019 - Feb 2020',
        index: 2,
        mobile: ['android', 'iOS'],
        web: ['react'],
        backend: ['nodeJS', 'express', 'mongoDB'],
    },
    {
        name: 'SUTD',
        fullName: 'Singapore University of Technology and Design',
        detail: '',
        role: 'Front End Engineer',
        roleDetial: 'Front End Engineer',
        duration: 'Jan 2020 - Jan 2021',
        index: 3,
        mobile: ['android', 'iOS', 'tablet'],
        web: ['react'],
        backend: [],
    },
    {
        name: 'Lionsbot',
        fullName: 'LionsBot International Pte Ltd',
        detail: '',
        role: 'Software Engineer',
        roleDetial: 'Software Engineer',
        duration: 'Jun 2021 - Present',
        index: 4,
        mobile: ['android', 'iOS', 'tablet'],
        web: [],
        backend: [],
    },
];
