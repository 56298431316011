export const projects = [
    {
        name: 'Softwares for Controlling Robots',
        company: '',
        tech: [],
        platform: ['mobile', 'android', 'iOS', 'web', 'react', 'tablet'],
        info: 'SDLC contribution from Design, Develop, Debug, Deploy and Maintain phases of mobile, tablet and web applications to control fleet of robots. Contributions are mostly on development, debugging and maintaning of the applications.',
        features: ['Real-time Control', 'User Friendly', 'UI-UX standardization', 'Real-time Custom Maps', 'Rich functionalities',],
        contribution: [
            { name: 'Design', value: 2, note: '' },
            { name: 'Development', value: 16, note: '' },
            { name: 'Debuging', value: 13, note: '' },
            { name: 'Deployment', value: 1, note: '' },
            { name: 'Maintenance', value: 8, note: '' },
        ]
    },
    {
        name: 'SmartParking Mobile App',
        company: '',
        tech: [],
        platform: ['mobile', 'android', 'iOS'],
        info: 'Smart Parking Application where users can book a parking spot using the mobile applicaion. The whole system includes web dashboard for admins, mobile application for users and the sensors netwrok.',
        features: ['Real-time sensors data', 'Use of google map APIs', 'TypeScripts', 'React Hooks', 'Redux'],
        contribution: [
            { name: 'Development', value: 3, note: '' },
            { name: 'Debuging', value: 3, note: '' },
        ]
    },
    {
        name: 'Donation Mobile App',
        company: '',
        tech: [],
        platform: ['mobile', 'android'],
        info: 'The application where the users can do donation using their mobile banking accounts to the organization of their choice.',
        features: ['Mobile transaction', 'Donation'], 
        contribution: [
            { name: 'Development', value: 3, note: '' },
            { name: 'Debuging', value: 3, note: '' },
        ]
    },
    {
        name: 'MMALPR Web App',
        company: '',
        tech: [],
        platform: ['web', 'react', 'backend', 'nodeJS'],
        info: 'The platform is to check the license plates inside the photo or video. The user can upload the photo or video to the web application and the application will give out the license plate numbers if any.',
        features: ['Full-Stack application', 'Material-UI', 'NodeJS', 'Rest API', 'Calling algorithm', 'File Upload', 'Digital Ocean Hosting'],
        contribution: [
            { name: 'Design', value: 2, note: '' },
            { name: 'Development', value: 4, note: '' },
            { name: 'Debuging', value: 4, note: '' },
            { name: 'Deployment', value: 1, note: '' },
        ]
    }
];

export const SDLC_LB_LC = projects[0].contribution.map(item => item.value);
export const SDLC_MGEC_SP = projects[1].contribution.map(item => item.value);
export const SDLC_MGEC_D = projects[2].contribution.map(item => item.value);
export const SDLC_MGEC_MMALPR = projects[3].contribution.map(item => item.value);

export const SDLC_LB_LC_Options = {
    chart: {
      type: 'donut',
    },
    plotOptions: {
        pie: {
          startAngle: 0,
          expandOnClick: true,
          offsetX: 0,
          offsetY: 0,
          customScale: 1,
          dataLabels: {
              offset: 0,
              minAngleToShowLabel: 10,
          }, 
          donut: {
            size: '55%',
            background: 'transparent',
          },      
        }
    },
    labels: projects[0].contribution.map(item => item.name),
    dataLabels: {
        enabled: true,
        formatter: function (val) {
          return Math.round(val) + "%"
        },
    }
};

export const SDLC_MGEC_SP_Options = {
    chart: {
      type: 'donut',
    },
    plotOptions: {
        pie: {
          startAngle: 0,
          expandOnClick: true,
          offsetX: 0,
          offsetY: 0,
          customScale: 1,
          dataLabels: {
              offset: 0,
              minAngleToShowLabel: 10,
          }, 
          donut: {
            size: '65%',
            background: 'transparent',
          },      
        }
    },
    labels: projects[1].contribution.map(item => item.name),
    dataLabels: {
        enabled: true,
        formatter: function (val) {
          return Math.round(val) + "%"
        },
    }
};

export const SDLC_MGEC_D_Options = {
    chart: {
      type: 'donut',
    },
    plotOptions: {
        pie: {
          startAngle: 0,
          expandOnClick: true,
          offsetX: 0,
          offsetY: 0,
          customScale: 1,
          dataLabels: {
              offset: 0,
              minAngleToShowLabel: 10,
          }, 
          donut: {
            size: '65%',
            background: 'transparent',
          },      
        }
    },
    labels: projects[2].contribution.map(item => item.name),
    dataLabels: {
        enabled: true,
        formatter: function (val) {
          return Math.round(val) + "%"
        },
    }
};

export const SDLC_MGEC_MMALPR_Options = {
    chart: {
      type: 'donut',
    },
    plotOptions: {
        pie: {
          startAngle: 0,
          expandOnClick: true,
          offsetX: 0,
          offsetY: 0,
          customScale: 1,
          dataLabels: {
              offset: 0,
              minAngleToShowLabel: 10,
          }, 
          donut: {
            size: '65%',
            background: 'transparent',
          },      
        }
    },
    labels: projects[3].contribution.map(item => item.name),
    dataLabels: {
        enabled: true,
        formatter: function (val) {
          return Math.round(val) + "%"
        },
    }
};

