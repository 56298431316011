import React from 'react';

// M-UI Components

// M-UI Icons
import SocialIcons from '../components/SocialIcons';

// Constants
import { theme } from '../util/constants/theme';

const Footer = () => {
    return (
        <div 
            style={{
                width: '100%',
                backgroundColor: theme.main,    
            }}>
            <SocialIcons />
            <p style={{ paddingBottom: 20, marginBlockEnd: 0, fontSize: 15, color: theme.white, opacity: 0.7}}>aungsanoo©2021</p>
        </div>
    );
}

export default Footer;