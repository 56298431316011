// Template

import React from 'react';

// M-UI Components
import {
    Tooltip,
} from '@material-ui/core';

// Material UI Icons
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import TabletIcon from '@material-ui/icons/Tablet';
import WebIcon from '@material-ui/icons/Web';
import StorageIcon from '@material-ui/icons/Storage';

const ProjectPlatforms = (props) => {
    const { platform } = props;
    return (
        <div style={{ flexDirection: 'row', padding: 5, opacity: 0.5 }}>
            {platform.includes('iOS') && (
                <Tooltip title="iOS" arrow>
                    <PhoneIphoneIcon color='secondary' fontSize='large' />
                </Tooltip>
            )}
            {platform.includes('android') && (
                <Tooltip title="Android" arrow>
                    <PhoneAndroidIcon color='secondary' fontSize='large' />
                </Tooltip>
            )}
            {platform.includes('tablet') && (
                <Tooltip title="Android Tablet" arrow>
                    <TabletIcon color='secondary' fontSize='large'/>
                </Tooltip>
            )}
            {platform.includes('react') && (
                <Tooltip title="React Dashboard" arrow>
                    <WebIcon color='secondary' fontSize='large' />
                </Tooltip>
            )}
            {platform.includes('nodeJS', 'express', 'mongoDB') && (
                <Tooltip title="Backend" arrow>
                    <StorageIcon color='secondary' fontSize='large' />
                </Tooltip>
            )}
        </div>
    )
};

export default ProjectPlatforms;
