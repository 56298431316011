import React from 'react';
import { Grid } from '@material-ui/core';
import { Works } from '../../util/experiences/Works';
import WorkCard from './WorkCard';


const WorkExperiences = () => {
    return (
        <div style={styles.container}>
            <h3>I partnered with these companies during my early career.</h3>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Grid
                    container
                    spacing={1}
                >
                    {Works.reverse().map((item, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <WorkCard item={item} />
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    );
};

const styles = {
    container: {
        width: '100%',
        backgroundColor: '',
    }
};

export default WorkExperiences;