import React from 'react';
import { Link } from 'react-scroll';

// M-UI components
import {
    Button,
} from '@material-ui/core';

const ExploreMoreButton = () => {
    return (
        <div style={{ position: 'absolute', bottom: 0, width: '100%', alignItems: 'center', }}>
            <Button
                color="primary"
                style={{ marginTop: 10, marginBottom: 25, }}
            >
                <Link  to={'Experiences'} spy={true} smooth={true} offset={-50}>Explore more to see my works</Link>
            </Button>
        </div>
    );
}

export default ExploreMoreButton;