import React from 'react';

// M-UI Components
import { IconButton, Tooltip } from '@material-ui/core'

// M-UI Icons
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
// import FacebookIcon from '@material-ui/icons/Facebook';

const url = 'https://www.linkedin.com/in/aungsanoo/';

const URLValidation = (url) => {
    const parsed = new URL(url);
    return ['https:'].includes(parsed.protocol);
}

const SocialIcons = () => {
    return (
        <div
            style={{
                flexDirection: 'row',
                paddingTop: 30, paddingBottom: 10,
                width: '50%',
                opacity: 0.70,
                marginLeft: 'auto', marginRight: 'auto',
            }}
        >
            <IconButton href={URLValidation(url) ? url : ''}>
                <Tooltip title="Go to my LinkedIn" arrow>
                    <LinkedInIcon fontSize='large'color='secondary'/>
                </Tooltip>
            </IconButton>
            <IconButton href={URLValidation(url) ? url : ''}>
                <Tooltip title="Go to my GitLab" arrow>
                    <GitHubIcon fontSize='large'color='secondary'/>
                </Tooltip>
            </IconButton>
            {/* <IconButton>
                <Tooltip title="Go to my Facebook" arrow>
                    <FacebookIcon fontSize='large'color='secondary'/>
                </Tooltip>
            </IconButton> */}
        </div>
    );
}

export default SocialIcons;
