import React from 'react';
import Chart from "react-apexcharts";

const BasicDonutChart = (props) => {
    const { series, options, height } = props;
    return(
        <div style={{
            marginLeft: 15,
            marginRight: 15,
            padding: 10,
            borderWidth: 10,
            borderRadius: 10,
            backgroundColor: '#FFFFFF',
        }}
        >
            <Chart
                options={options}
                series={series}
                type="donut"
                height={height || 225}
            />
        </div>
    )
};

export default BasicDonutChart;
