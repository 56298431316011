import React from 'react';

// M-UI components
import {
    Tooltip,
} from '@material-ui/core';

// Material UI Icons
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import TabletIcon from '@material-ui/icons/Tablet';
import WebIcon from '@material-ui/icons/Web';
import StorageIcon from '@material-ui/icons/Storage';

const WelcomeInfo = () => {
    return (
        <div
            style={{
                flexDirection: 'row',
                opacity: 0.5,
                marginTop: 20,
            }}
        >
            <Tooltip title="iOS" arrow>
                <PhoneIphoneIcon fontSize='large' color='secondary' />
            </Tooltip>
            <Tooltip title="Android" arrow>
                <PhoneAndroidIcon fontSize='large' color='secondary' />
            </Tooltip>
            <Tooltip title="Android Tablet" arrow>
                <TabletIcon fontSize='large' color='secondary' />
            </Tooltip>
            <Tooltip title="React Dashboard" arrow>
                <WebIcon fontSize='large' color='secondary' />
            </Tooltip>
            <Tooltip title="Backend" arrow>
                <StorageIcon fontSize='large' color='secondary' />
            </Tooltip>
        </div>
    );
}

export default WelcomeInfo;