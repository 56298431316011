import React from 'react';
import Bounce from 'react-reveal/Bounce';

// M-UI Components

// Components
import ProjectHeader from './ProjectHeader';
import ProjectInfo from './ProjectInfo';
import ProjectDetails from './ProjectDetails';

// Constants
import { theme } from '../../util/constants/theme';

const ProjectCard = (props) => {
    const { project, series, options } = props;
    return (
        <Bounce right>
            <div
                style={{
                    width: '95%',
                    minHeight: 300,
                    paddingBottom: 10,
                    backgroundColor: theme.white,
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    justifyItems: 'center',
                    borderRadius: 15,
                    overflow: 'hidden',
                    borderWidth: 1, borderStyle: 'solid', borderColor: theme.main, borderBottomWidth: 5, borderTopWidth: 0,
                }}
            >
                <ProjectHeader
                    project={project}
                />
                <ProjectInfo
                    project={project}
                />
                <ProjectDetails
                    project={project}
                    series={series}
                    options={options}
                />
            </div>
        </Bounce>
        
    )
};

export default ProjectCard;
