import React, { Component } from 'react';

// Components
import NavBar from '../components/NavBar';
import PageBreak from '../components/PageBreak';
import Footer from '../components/Footer';

// Screens
import Landing from './Landing';
import About from './About';
import Experiences from './Experiences';
import Projects from './Projects';
import Skills from './Skills';
import Contact from './Contact';

class MainPage extends Component {
    render() {
        return(
            <div style={{ overflowX: 'hidden' }}>
                <NavBar />
                <Landing />
                <PageBreak />
                <About />
                <PageBreak />
                <Experiences />
                <PageBreak />
                <Projects />
                <PageBreak />
                <Skills />
                <PageBreak />
                <Contact />
                <Footer />
            </div>
        );
    }
};

export default MainPage;