import React, { useState } from 'react';
import {
    IconButton,
    Tooltip,
    Collapse,
} from '@material-ui/core';

// Material UI Icons
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import TabletIcon from '@material-ui/icons/Tablet';
import WebIcon from '@material-ui/icons/Web';
import StorageIcon from '@material-ui/icons/Storage';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const WorkIcons = (item) => {
    const { mobile, web, backend } = item;
    return (
        <div style={{ flexDirection: 'row', padding: 5, opacity: 0.5 }}>
            {mobile.includes('iOS') && (
                <Tooltip title="iOS" arrow>
                    <PhoneIphoneIcon />
                </Tooltip>
            )}
            {mobile.includes('android') && (
                <Tooltip title="Android" arrow>
                    <PhoneAndroidIcon />
                </Tooltip>
            )}
            {mobile.includes('tablet') && (
                <Tooltip title="Android Tablet" arrow>
                    <TabletIcon />
                </Tooltip>
            )}
            {web.includes('react') && (
                <Tooltip title="React Dashboard" arrow>
                    <WebIcon />
                </Tooltip>
            )}
            {backend.includes('nodeJS', 'express', 'mongoDB') && (
                <Tooltip title="Backend" arrow>
                    <StorageIcon />
                </Tooltip>
            )}
        </div>
    )
}

const WorkCard = (props) => {
    const { name, role, duration, fullName } = props.item;
    const [ showDetail, setShowDetail ] = useState(false);

    return (
        <div style={{
            backgroundColor: '#FFFFFF',
            borderRadius: 20,
            padding: 5,
            margin: 10,
            marginLeft: 15,
            marginRight: 15,
        }}>
            <h2>{name}</h2>
            <h5>{fullName}</h5>
            <h3>{role}</h3>
            <p>{duration}</p>
            <p>{showDetail}</p>
            {WorkIcons(props.item)}
            <div>
                <Tooltip title="More Details" arrow>
                    <IconButton
                        onClick={() => setShowDetail(!showDetail)}
                    >
                        <ExpandMoreIcon className={{ transform: 'rotate(180deg)' }}/>
                    </IconButton>
                </Tooltip>
            </div>
            <Collapse in={showDetail} timeout="auto" unmountOnExit>
                <div>
                    <p style={{ opacity: 0.5, marginTop: 30, marginBottom: 30 }}>Coming Soon</p>
                </div>
            </Collapse>
        </div>
    );
};

// const styles = {}

export default WorkCard;