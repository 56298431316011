import React, { useState } from 'react';

// M-UI
import {
    Grid,
    Hidden,
    IconButton,
    Collapse,
} from '@material-ui/core';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';

// Components
import Logo from './Logo';
import NavBarButtons from './NavBarButtons';

const menuButtons = ['About', 'Experiences', 'Projects', 'Skills', 'Contact'];

const NavBar = () => {
    const [showMenu, setShowMenu] = useState(false);
    return (
        <div className='navigation-bar'>
            <Grid
                container
                spacing={10}
                alignItems='center'
            >
                <Grid item xs={6} sm={6} md={4} container justify='flex-start'>
                    <Logo />
                </Grid>
                <Hidden smDown>
                    <Grid item md={8} direction='row' container justify='flex-end'>
                        {menuButtons.map((menu, index) => (
                            <NavBarButtons menu={menu} key={index}/>
                        ))}
                        <div style={{ marginRight: 30,}} />
                    </Grid>
                </Hidden>
                <Hidden mdUp>
                    <Grid item xs={6} sm={6} container justify='flex-end'>
                        <div style={{ marginRight: 20 }}>
                            <IconButton onClick={() => setShowMenu(!showMenu)}>
                                <ViewHeadlineIcon color='secondary' />
                            </IconButton>
                        </div>
                    </Grid>
                </Hidden>
            </Grid>
            <Collapse in={showMenu} timeout="auto" unmountOnExit>
                <div style={{ backgroundColor: '#2867BC', paddingBottom: 10, borderBottomRightRadius: 5, borderBottomLeftRadius: 5, borderWidth: 0, borderBottomWidth: 2, borderColor: 'orange', borderStyle: 'solid' }}>
                    {menuButtons.map((menu, index) => (
                        <NavBarButtons menu={menu} key={index}/>
                    ))}
                </div>
            </Collapse>
        </div>
    )
}

export default NavBar;
