import { theme } from '../constants/theme';

// Skills Chart ========================================
const skillsArray = [
    {
        name: 'React Native',
        value: 18, // nov18-may21
        color: theme.front_end,
        tech: 'frontend',
    },
    {
        name: 'React',
        value: 14, // oc18-apr21
        color: theme.front_end,
        tech: 'frontend',
    },
    {
        name: 'React Hooks',
        value: 15,
        color: theme.front_end,
        tech: 'frontend',
    },
    {
        name: 'Flutter',
        value: 12, // till apr22
        color: theme.front_end,
        tech: 'frontend',
    },
    {
        name: 'Redux',
        value: 20,
        color: theme.front_end,
        tech: 'frontend',
    },
    {
        name: 'Material-UI',
        value: 12,
        color: theme.front_end,
        tech: 'frontend',
    },
    {
        name: 'LeafLet-Maps',
        value: 13,
        color: theme.front_end,
        tech: 'frontend',
    },
    {
        name: 'JavaScript',
        value: 31, //may21
        color: theme.language,
        tech: 'language',
    },
    {
        name: 'TypeScript',
        value: 3,
        color: theme.language,
        tech: 'language',
    },
    {
        name: 'Dart',
        value: 12, // till apr21
        color: theme.language,
        tech: 'language',
    },
    {
        name: 'NodeJS',
        value: 11,
        color: theme.backend,
        tech: 'backend',
    },
    {
        name: 'ExpressJS',
        value: 11,
        color: theme.backend,
        tech: 'backend',
    },
    {
        name: 'MongoDB',
        value: 11,
        color: theme.backend,
        tech: 'backend',
    },
    {
        name: 'AWS-Amplify',
        value: 3,
        color: theme.backend,
        tech: 'backend',
    },
]

export const skillsData = [{
    data: skillsArray.map((skill) => skill.value)
}];

export const skillChartOptionsData = {
    chart: {
        type: 'bar',
        height: 350
    },
    plotOptions: {
        bar: {
            horizontal: true,
            distributed: true
        }
    },
    dataLabels: {
        enabled: false
    },
    xaxis: {
        categories: skillsArray.map((skill) => skill.name)
    },
    colors: skillsArray.map((skill) => skill.color),
    grid: {
        show: true,
        xaxis: {
            lines: {
                show: true,
            }
        },
        yaxis: {
            lines: {
                show: false,
            }
        },  
    }
};

// Full-Stack Contribution =============================
const frontTotal = skillsArray.reduce((accumulator, currentValue) => {
    const { tech, value } = currentValue;
    if (tech === 'frontend') return accumulator + value;
    return accumulator;
}, 0)

const backTotal = skillsArray.reduce((accumulator, currentValue) => {
    const { tech, value } = currentValue;
    if (tech === 'backend') return accumulator + value;
    return accumulator;
}, 0)


export const fullStackData = [frontTotal, backTotal];

export const fullStackOptionsData = {
    chart: {
      type: 'donut',
    },
    plotOptions: {
        pie: {
            startAngle: 0,
            expandOnClick: true,
            offsetX: 0,
            offsetY: 0,
            customScale: 1,
            dataLabels: {
                offset: 0,
                minAngleToShowLabel: 10
            }, 
            donut: {
                size: '65%',
                background: 'transparent',
                labels: {
                show: true,
                name: {
                    show: true,
                    fontSize: '22px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    color: undefined,
                    offsetY: -10,
                    formatter: function (val) {
                    return val
                    }
                },
                value: {
                    show: true,
                    fontSize: '16px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    color: undefined,
                    offsetY: 16,
                    formatter: function (val) {
                    return val
                    }
                },
                total: {
                    show: false,
                    showAlways: false,
                    label: 'Total',
                    fontSize: '22px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    color: '#373d3f',
                    formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                        return a + b
                    }, 0)
                    }
                }
                }
            },      
        }
    },
    labels: ['Front-End', 'Back-End'],
    dataLabels: {
        enabled: true,
        formatter: function (val) {
          return Math.round(val) + "%"
        },
    }
} 

// SDLC Contribution ===================================
const sdlc = [
    { name: 'Design', value: 3, note: '' },
    { name: 'Development', value: 31, note: '' },
    { name: 'Debuging', value: 23, note: '' },
    { name: 'Deployment', value: 2, note: '' },
    { name: 'Maintenance', value: 5, note: '' },
]

export const SDLCData = sdlc.map(item => item.value);

export const SDLCOptionsData = {
    chart: {
      type: 'donut',
    },
    plotOptions: {
        pie: {
            startAngle: 0,
            expandOnClick: true,
            offsetX: 0,
            offsetY: 0,
            customScale: 1,
            dataLabels: {
                offset: 0,
                minAngleToShowLabel: 10
            }, 
            donut: {
                size: '65%',
                background: 'transparent',
                labels: {
                show: true,
                name: {
                    show: true,
                    fontSize: '22px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    color: undefined,
                    offsetY: -10,
                    formatter: function (val) {
                    return val
                    }
                },
                value: {
                    show: true,
                    fontSize: '16px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    color: undefined,
                    offsetY: 16,
                    formatter: function (val) {
                    return val
                    }
                },
                total: {
                    show: false,
                    showAlways: false,
                    label: 'Total',
                    fontSize: '22px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    color: '#373d3f',
                    formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                        return a + b
                    }, 0)
                    }
                }
                }
            },      
        }
    },
    labels: sdlc.map(item => item.name),
    dataLabels: {
        enabled: true,
        formatter: function (val) {
          return Math.round(val) + "%"
        },
    }
}
