// Template

import React from 'react';

// M-UI Components
import { Grid } from '@material-ui/core';

// Constants

// Components
import ProjectFeatures from './ProjectFeatures';
import ProjectContribution from './ProjectContribution';

const ProjectDetails = (props) => {
    const { project, series, options } = props;
    return (
        <div
            style={{
                paddingTop: 10,
                paddingBottom: 10,
            }}
        >
            <Grid container spacing={0} alignItems='center'>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <ProjectFeatures
                        project={project}
                    />
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <ProjectContribution
                        series={series}
                        options={options}
                    />
                </Grid>
            </Grid>
        </div>
    )
};

export default ProjectDetails;
