// Template

import React from 'react';

// M-UI Components
import { Grid } from '@material-ui/core';

// Constants

const ProjectFeatures = (props) => {
    const { features } = props.project;
    return (
        <div
            style={{
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
            }}
        >
            <Grid container spacing={2} >
                {features.map((feature, index) => (
                    <Grid item lg={6} md={6} sm={6} xs={6} key={index}>
                        {feature}
                    </Grid>
                ))}
            </Grid>
        </div>
    )
};

export default ProjectFeatures;
