import React from 'react';
import { Link } from 'react-scroll';

// M-UI
import {
    IconButton
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';



const Logo = () => {
    return (
        <div style={{ marginLeft: 30 }}>
            <IconButton color="secondary" >
                <Link  to='Home' spy={true} smooth={true}><HomeIcon /></Link>
            </IconButton>
        </div>
    )
}

export default Logo;


