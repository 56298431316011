import React, { Component } from 'react';

// M-UI components

// M-UI icons

// Components
import WelcomeInfo from './WelcomeInfo';
import ExploreMoreButton from './ExploreMoreButton';

const { innerWidth: width, innerHeight: height } = window;

const isSmallHieght = (height) => {
    return height < 400;
}; 

class Landing extends Component {
    constructor(props) {
        super(props);
        this.state={
            screenHeight: height,
            screenWidth: width,
        }
    }

    componentDidMount = () => {
        this.updateDimensions();
        // window.addEventListener('resize', this.updateDimensions);
    };

    updateDimensions = () => {
        const { screenHeight: oldHeight } = this.state
        const { innerWidth: screenWidth, innerHeight: screenHeight } = window;
        const diff = Math.abs(oldHeight - screenHeight)
        if (diff > 100) {
            this.setState({
                screenWidth,
                screenHeight,
            });
        }
    }

    render() {
        const { screenHeight } = this.state;
        return (
            <div style={{  height: screenHeight }} id='Home'>
                <WelcomeInfo
                    isSmallHieght={isSmallHieght}
                    screenHeight={screenHeight}
                />
                {!isSmallHieght(screenHeight) && (
                    <ExploreMoreButton />
                )}
            </div>
        )
    }
}

export default Landing;
