import React, {useState} from 'react';
import emailjs from 'emailjs-com';

// M-UI components
import { TextField, Button, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// M-UI Icons
import EmailIcon from '@material-ui/icons/Email';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
          margin: theme.spacing(2),
          width: '30ch',
    },
        button: {
            margin: theme.spacing(1),
        },
    },
  }));

const Contact = () => {
    const classes = useStyles();
    const [submitted, setSubmitted] = useState(false);
    const [showAlerrt, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [mailContent, setMailContent] = useState({
        name: '',
        message: '',
    });

    const handleChange = (event) => {
        const {name, value} = event.target;
        setMailContent((previous) => {
            return {
                ...previous,
                [name]: value,
            }
        })
    };

    const handleSubmit = (event) => {
        const {
            REACT_APP_EMAILJS_SERVICEID: serviceId,
            REACT_APP_EMAILJS_TEMPLATEID: templateId,
            REACT_APP_EMAILJS_USERID: userId,
        } = process.env;
        diableSubmitButton(true);
        emailjs.send(serviceId, templateId, mailContent, userId)
        .then((res) => {
            if (res.status === 200) {
                toggleAlert(true, 'Message successfully sent!');
                diableSubmitButton(false);
            }
        })
        .catch((e) => toggleAlert(true, 'Something went wrong!'));
        event.preventDefault();
    }

    const diableSubmitButton = (status) => {
        setSubmitted(status);
    };

    const toggleAlert = (status, message) => {
        setShowAlert(status);
        setAlertMessage(message);
    };

    return (
        <div className='contact-container' style={{ marginBottom: 180 }} id='Contact'>
            <h1 className='header'>Contact</h1>
            <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
                <div>
                    <TextField
                        id="outlined-basic" label="Name" variant="outlined" required
                        value={mailContent.name}
                        name='name'
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <TextField
                        id="outlined-multiline-static" label="Message" multiline rows={4} variant="outlined" required
                        value={mailContent.message}
                        name='message'
                        onChange={handleChange}
                    />
                </div>
                <Button
                    variant='outlined'
                    color="primary"
                    className={classes.button}
                    size='large'
                    type='submit'
                    endIcon={<EmailIcon />}
                    disabled={mailContent.name === '' || mailContent.message === '' || submitted}
                >
                    Send
                </Button>
                <Snackbar open={showAlerrt} autoHideDuration={5000} onClose={() => toggleAlert(false, '')}message={alertMessage} />
            </form>
        </div>
    )
};

export default Contact;
